@import "semantic-ui/site/globals/site.variables";

html,
body,
.main.container {
  height: 100% !important;
}

sup {
  line-height: 24px;
}

a.underlined {
  color: inherit !important;
}

.underlined {
  text-decoration: underline;
}

.underlined>sup {
  text-decoration: none;
}

.h100 {
  height: 100%;
}

.over-y {
  overflow-y: auto;
}

.over-y-hidden {
  overflow-y: hidden !important;
}

.ui.input input.readonly {
  background-color: #f5f5f0 !important;
}

.ui.disabled.input {
  z-index: 2;
}

.link {
  font-weight: normal;
  border-bottom: 1px dashed #262626;
  cursor: pointer;
}

.header .link {
  font-weight: normal !important;
  font-size: 0.6em !important;
  border-bottom: 1px dashed #262626 !important;
  cursor: pointer !important;
}

.toolbarSpacer {
  width: 100%;
  height: 60px;
}

.sidebar {
  width: 300px;
}

.content {
  tabkey: padding 0.3s;
  /* overflow-y: hidden; */
  flexgrow: 1;
  /* padding: 1em; */
}

.contentShift {
  padding-left: 300px;
}

.toolbar {
  transition: padding 0.3s !important;
}

.toolbarShift {
  padding-left: 300px !important;
}

.nopaddings {
  padding: 0 !important;
}

.nomargins {
  margin: 0 !important;
}

.noborder {
  border: none !important;
}

.row_container {
  background-color: #fff;
  position: relative;
  overflow: hidden;
  /* height: 50px; */
  z-index: 1;
}

.row_container:hover {
  background-color: #fff;
  overflow: visible;
  z-index: 1000;
}

.alertbox {
  margin-bottom: 2em;
  position: fixed;
  right: 0;
  bottom: 0;
  height: auto;
  width: 300px;
  padding: 1em;
  z-index: 1001;

  @media (max-width: 1024px) {
    margin-bottom: 3em;
    width: 100%;
    padding: 0;
  }
}

.alertbox.empty {
  padding: 0;
}

/* MY CSS */
body,
.body {
  background: radial-gradient(at top, #feffff, #efefee) !important;
  background-attachment: fixed !important;

  /* background: #ECEFF1; */
  /*linear-gradient(to top left, #FEFFFF, powderblue)*/
}

html {
  font-size: 12px;
}

i.icon.light.grey {
  color: #ccc !important;
}

i.icon.very.light.grey {
  color: #eee !important;
}

i.veryhuge.icon {
  line-height: 1;
  vertical-align: middle;
  font-size: 7em;
}

.pointer {
  cursor: pointer;
}

.right.padded {
  padding-right: 0.5rem !important;
}

.left.floated {
  float: left !important;
}

.right.floated {
  float: right !important;
}

.left.aligned {
  text-align: left !important;
}

.right.aligned {
  text-align: right !important;
}

.nomargin {
  margin: 0 !important;
}

.zindex10 {
  z-index: 10;
}

.hidden:not(.divider) {
  display: none;
}

.right.menu>.special.item {
  padding: 1em !important;
}

.right.menu>.special.item>.ui.steps {
  margin-bottom: 0em;
}

.right.menu>.special.item>.statistic {
  width: auto;
  margin-top: 0 !important;
}

.right.menu>.special.item>.ui.header {
  margin: 0 !important;
}

.ui.secondary.pointing.massive.menu>.right.menu>.icon.item {
  padding-left: 0 !important;
}

.ui.secondary.pointing.massive.menu .item {
  padding: 1.6em 1.4em;
}

.ui.secondary.pointing.massive.menu>.right.menu>.special.item>.statistic>.text {
  min-height: auto;
}

.right.menu .item span {
  margin-right: 0.4em;
}

.right.menu>.ui.dropdown .menu:not(.vertical) {
  display: none;
}

.ui.secondary.pointing.massive.menu>.right.menu .active.item {
  border-bottom-color: transparent !important;
}

.right.menu>.special.item>.statistic>.label {
  font-weight: normal;
}

.bg.inverted.violet.gradient {
  background: radial-gradient(at top, #cfd8dc, #757575) !important;
}

.ui.toggle.checkbox input:checked~.box:before,
.ui.toggle.checkbox input:checked~label:before {
  background-color: #757575 !important;
}

.ui.toggle.checkbox label,
.ui.toggle.checkbox input:checked~.box,
.ui.toggle.checkbox input:checked~label {
  color: #cfd8dc !important;
}

body>#root,
body>#root>.ui.body,
body>#root>.ui.body>.pushable,
body>#root>.ui.body>.pushable>div.container,
body>#root>.ui.body>div>div>div.login-form {
  min-height: 100% !important;
}

.height100perc,
body>#root,
body>#root>.ui.body {
  height: 100% !important;
}

.ui.body>.no-pushable-padding {
  padding-top: 0 !important;
  height: 100%;
}

.ui.body>.pushable {
  padding-top: 4 * @relativeSmall !important;
}

.login-form {
  margin-top: -4 * @relativeSmall;
}

.ui.body>.pushable.disabled {
  padding-top: 0 !important;
}

.width100perc {
  width: 100% !important;
}

.item.logo {
  padding: 0.8em 1.3em !important;
}

.item.logo>img {
  width: 107px !important;
  margin: 0.75rem 0em !important;
}

.logobg {
  background-image: url("/records.png");
  background-position: left;
  background-size: cover;
  width: 107px !important;
  height: 21px !important;
}

.loader {
  width: 40px;
  height: 40px;
  -webkit-animation: loader 0.6s linear;
  animation: loader 1s linear;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

.ui.image.minilogo {
  background-image: url("/new.svg");
  background-position: left;
  background-size: cover;
  width: 62px !important;
  height: 70px !important;
}

.ui.file.button {
  position: relative;
}

.ui.file.button input,
.ui.file.button .input {
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
  opacity: 0;
  -ms-filter: "alpha(opacity=0)";
  direction: ltr;
  cursor: pointer;
  width: 100% !important;
  height: 100%;
}

.ui.basic.label>img {
  margin: -0.5833em -0.833em;
}

.ui.transparent.input.area {
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
  opacity: 0;
  -ms-filter: "alpha(opacity=0)";
  direction: ltr;
  cursor: pointer;
  width: 100% !important;
  height: 100%;
}

.ui.padded.image,
.ui.padded.image>img {
  padding: 1em !important;
}

.ui.main.container {
  /* padding-top: 1rem; */
  background: none !important;
}

.ui.main.menu {
  background: #fff !important;
}

.ui.main.menu.fixed {
  transition: top 0.5s ease-in-out;
}

.ui.fixed.main.menu.hidden {
  top: -55px;
  display: flex !important;
}

.ui.secondary.bottom.fixed.menu {
  bottom: 42px;
}

.ui.header.middle {
  padding: 0.45em 0 0;
}

.ui.stretched.header {
  margin: 0 -1em;
  padding: 0.5em 1em;
  background: #eee;
}

.ui.header>span,
.header>span {
  font-weight: normal !important;
  margin-right: 3px;
}

.ui.button .username {
  text-align: left;
  color: #666;
}

.ui.button .username>h5 {
  font-weight: 500;
  margin-bottom: 0.1em;
}

.ui.button .username>span {
  font-weight: 400;
}

/* CARDS */

.ui.fluid.input {
  width: 100%;
}

.ui.image,
.ui.images .image,
.ui.images img,
.ui.images svg {
  overflow: visible !important;
}

.square.medium.picture {
  width: 150px;
  height: 150px;
  background-size: cover;
  background-position: center;
  box-shadow: 0 0 0 2px #fff, inset 0 2px 0 rgba(0, 0, 0, 0.08), 0 2px 2px 2px rgba(0, 0, 0, 0.1);
  background-color: #cfd8dc;
  overflow: hidden;
  text-align: center;
}

.square.user.picture {
  width: 40px;
  height: 40px;
  background-size: cover;
  background-position: center;
  background-color: #cfd8dc;
  overflow: hidden;
  box-shadow: 0 0 0 2px #fff, inset 0 2px 0 rgba(0, 0, 0, 0.08), 0 2px 2px 2px rgba(0, 0, 0, 0.1);
}

.square.avatar.picture {
  width: 32px;
  height: 32px;
  background-size: cover;
  background-position: center;
  background-color: #cfd8dc;
  overflow: hidden;
  box-shadow: 0 0 0 2px #fff, inset 0 2px 0 rgba(0, 0, 0, 0.08), 0 2px 2px 2px rgba(0, 0, 0, 0.1);
}

.ui.image svg,
.square.picture svg {
  left: 0 !important;
  top: 0 !important;
  margin: 0 !important;
}

.ui.centered.image {
  display: block;
}

i.violet.icon,
i.big.violet.icon,
i.small.violet.icon,
i.large.violet.icon,
i.huge.violet.icon {
  color: #757575 !important;
}

.square.medium.picture i.violet.icon {
  color: #757575 !important;
  font-size: 150px !important;
  line-height: 150px !important;
  width: 1em;
  height: 1em;
}

.square.custom.picture i.violet.icon {
  color: #757575 !important;
  font-size: 100px !important;
  line-height: 150px !important;
  width: 1em;
  height: 1em;
}

.square.user.picture i.violet.icon {
  color: #757575 !important;
  font-size: 40px !important;
  line-height: 40px !important;
}

.square.avatar.picture i.violet.icon {
  color: #757575 !important;
  font-size: 40px !important;
  line-height: 40px !important;
}

.ui.basic.blue.label.basic-no-bg,
.ui.basic.teal.label.basic-no-bg,
.ui.basic.violet.label.basic-no-bg,
.ui.basic.green.label.basic-no-bg,
.ui.basic.olive.label.basic-no-bg,
.ui.basic.orange.label.basic-no-bg,
.ui.basic.red.label.basic-no-bg,
.ui.basic.brown.label.basic-no-bg,
.ui.basic.pink.label.basic-no-bg,
.ui.basic.grey.label.basic-no-bg {
  background: none !important;
}

.statuses>.ui.label.basic-no-bg {
  border-width: 1px;
  margin-bottom: 4px;
}

.labeled.image .ui[class*="right ribbon"].label {
  z-index: 999;
}

/* DASHBOARD */
.dashboard .stats .statistic>.text.value {
  min-height: auto !important;
}

.dashboard .investments .slider-list {
  height: auto !important;
  padding: 0.1em 0px !important;
}

.dashboard .investments .slider-slide {
  padding: 0 1em 0 0;
  position: initial !important;
}

.dashboard .apartments .slider-list {
  height: auto !important;
  padding: 0.1em 0px !important;
}

.dashboard .apartments .slider-slide {
  padding: 0 1em 0 0;
  position: initial !important;
}

.dashboard .vertical.label {
  position: absolute;
  left: -7em;
  top: 5em;
  -webkit-transform: rotate(270deg);
  transform: rotate(270deg);
}

.icon .ui.mini.image {
  display: inline-block;
  width: 20px !important;
  opacity: 0.7;
  vertical-align: text-bottom;
}

.ui.label.rate>.detail {
  margin-left: 0.2em;
}

.ui.image.flag {
  background-image: url(/langsprite.png);
  background-size: auto 100%;
  height: 40px;
  width: 40px;
}

.ui.image.flag.en {
  background-position: 0 0;
}

.ui.image.flag.ru {
  background-position: 200px 0;
}

.ui.image.flag.ge {
  background-position: 160px 0;
}

.ui.image.flag.small {
  background-size: auto 100%;
  height: 24px !important;
  width: 24px !important;
  margin-top: -2px !important;
}

.ui.image.flag.en.small {
  background-position: 0 0;
}

.ui.image.flag.ru.small {
  background-position: 120px 0;
}

.ui.image.flag.ge.small {
  background-position: 96px 0;
}

.ui.image.flag.mini {
  background-size: auto 100%;
  height: 16px !important;
  width: 16px !important;
}

.ui.image.flag.en.mini {
  background-position: 0 0;
}

.ui.image.flag.ru.mini {
  background-position: 80px 0;
}

.ui.image.flag.ge.mini {
  background-position: 64px 0;
}

.ui.image.currency {
  background-image: url(/cryptosprite.png);
  background-size: auto 100%;
  height: 40px;
  width: 40px;
}

.ui.image.currency.container,
.ui.image.flag.container {
  background-image: none !important;
}

.ui.image.currency.bitcoin {
  background-position: 200px 0;
}

.ui.image.currency.litecoin {
  background-position: 120px 0;
}

.ui.image.currency.ethereum {
  background-position: 160px 0;
}

.ui.image.currency.dollar {
  background-position: 40px 0;
}

.ui.image.currency.zcash {
  background-position: 80px 0;
}

.ui.image.currency.etc {
  background-position: left;
}

.ui.image.currency.small {
  background-size: auto 100%;
  height: 24px !important;
  width: 24px !important;
  margin-top: -2px !important;
}

.ui.image.currency.bitcoin.small {
  background-position: 120px 0;
}

.ui.image.currency.litecoin.small {
  background-position: 72px 0;
}

.ui.image.currency.ethereum.small {
  background-position: 96px 0;
}

.ui.image.currency.zcash.small {
  background-position: 48px 0;
}

.ui.image.currency.dollar.small {
  background-position: 24px 0;
}

.ui.image.currency.etc.small {
  background-position: 0 0;
}

.ui.image.currency.big {
  background-size: auto 100%;
  height: 64px;
  width: 64px;
}

.ui.image.currency.bitcoin.big {
  background-position: 320px 0;
}

.ui.image.currency.litecoin.big {
  background-position: 192px 0;
}

.ui.image.currency.ethereum.big {
  background-position: 256px 0;
}

.ui.image.currency.zcash.big {
  background-position: 128px 0;
}

.ui.image.currency.dollar.big {
  background-position: 64px 0;
}

.ui.image.currency.etc.big {
  background-position: 0 0;
}

@media only screen and (max-width: 767px) {

  .ui.unstackable.items>.item>.image.currency,
  .ui.unstackable.items>.item>.image.currency>img {
    width: 40px !important;
  }

  .ui.unstackable.items>.item>.image.currency.big,
  .ui.unstackable.items>.item>.image.currency.big>img {
    width: 64px !important;
  }

  .ui.unstackable.items>.item>.image.currency.small,
  .ui.unstackable.items>.item>.image.currency.small>img {
    width: 24px !important;
  }
}

@media only screen and (max-width: 767px) {
  .menuhead {
    display: none;
  }
}

.ui.statistics.getback .statistic {
  padding: 10px;
}

.ui.statistics.getback .statistic.active {
  background-color: #eee;
}

.ui.statistics.currency .icon,
.ui.list.currency .icon {
  border-radius: 500em !important;
  padding: 1.5rem 0.5rem 1.4rem !important;
  line-height: 1.6rem !important;
  width: 4.5rem !important;
  height: 4.5rem !important;
  font-size: 2.8rem;
  margin-right: 0 !important;
}

.ui.statistics.currency .euro.icon,
.ui.list.currency .euro.icon {
  line-height: 1.8rem !important;
  padding: 1.5rem 1.6rem 1.2rem 1.3rem !important;
}

.ui.statistics.currency>.statistic>.label {
  padding: 0.5em 0;
}

/* PROJECT */
.ui.sell.button {
  height: 51.7px !important;
}

.project.slider .item>.image {
  height: 300px !important;
  background-position: center;
  background-size: cover;
}

.project.slider,
.project.slider>div,
.project.slider .item {
  height: 300px !important;
  position: relative;
}

.project.slider {
  margin-top: -1.65em;
}

.no-border,
.no-border.segment,
.no-border.segments {
  border: none !important;
}

.no-margin,
.no-margin.segment,
.no-margin.segments {
  margin: 0 !important;
  width: 100% !important;
}

.antimargin {
  margin-left: -1em;
  margin-right: -1em;
  margin-top: -1em;
}

.no-padding {
  padding: 0 !important;
}

.slider_container .grad_bg {
  position: absolute;
  z-index: 1;
  height: 105px;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  background-image: linear-gradient(to top, rgba(10, 10, 10, 0.4), rgba(10, 10, 10, 0.3), rgba(10, 10, 10, 0));
}

.headblock {
  position: relative;
  width: 100%;
}

.head {
  display: block !important;
  position: absolute !important;
  margin: 0 !important;
}

.head.left {
  left: 1rem;
}

.head.bottom {
  bottom: 1rem;
}

.head.right {
  right: 1rem;
}

.head.top {
  top: 1rem;
}

.ui.label>.detail:first-child {
  margin-left: 0;
  margin-right: 0.3em;
}

.isostatus {
  display: inline-flex;
  text-align: center;
  flex-direction: row;
  align-items: center;
}

.pie {
  width: 5rem;
  height: 5rem;
  position: relative;
  margin-right: 1rem;
}

.pie>.ui.header {
  position: absolute;
  top: 2rem;
  margin: 0;
  width: 100%;
  text-align: center;
}

.isolist {
  margin-top: 0 !important;
}

.slider_container .ui.statistic:first-child {
  width: min-content;
  margin-top: 0.4rem !important;
}

.ui.statistic>.value {
  font-weight: lighter !important;
}

.ui.statistic>.label {
  white-space: nowrap;
  /*font-weight: normal !important;*/
}

.ui.statistic>.label>.divider {
  font-size: 0.7rem;
  color: #24c1bb;
  font-weight: normal;
  margin: 0.38rem 0 !important;
}

.ui.statistic>.value>div:not(.image) {
  display: block;
  font-size: 1rem;
  line-height: 1rem;
  color: rgba(0, 0, 0, 0.5);
  font-weight: normal;
  white-space: nowrap;
}

.slider_container.slider .item,
.slider_container.slider .item>.image {
  height: 450px !important;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-color: #fff;
}

.mini-gallery.slider {
  margin: 1rem 0 0;
}

.mini-gallery.slider .item {
  padding-right: 10px;
  width: 141px;
}

.mini-gallery.slider .item>.image {
  height: 107px !important;
  width: 131px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-color: #fff;
}

.mini-gallery.slider,
.wide-gallery.slider>div,
.wide-gallery.slider .item {
  height: 107px !important;
  position: relative;
}

.wide-gallery.slider {
  margin: -1rem 1rem;
  width: calc(100% - 1.6rem) !important;
}

.wide-gallery.slider .item {
  padding-right: 10px;
  width: 228px;
}

.wide-gallery.slider .item>.image {
  height: 150px !important;
  width: 218px;
  background-position: center;
  background-size: cover;
}

.wide-gallery.slider,
.wide-gallery.slider>div,
.wide-gallery.slider .item {
  height: 150px !important;
  position: relative;
}

.ui.margined.image {
  margin: 1rem;
}

.ui.slide.reveal .ui.header {
  white-space: normal !important;
}

.ui.items .item>.content>h4.small.header {
  font-size: 1em;
}

.logout.button {
  padding: 1em !important;
  vertical-align: bottom !important;
}

.ui.statistics .divider {
  width: 100%;
}

.auctionblock .statistic {
  width: 100%;
}

.auctionblock .statistic .value {
  width: 60%;
  text-align: left !important;
}

.auctionblock .statistic .label {
  width: 40%;
  text-align: right !important;
}

/* TOP SIDEBAR */
.pushable {
  overflow-y: hidden;
  /* height: auto !important; */
  min-height: 100% !important;
}

.body>.pushable.mainframe {
  padding-top: 3.4rem !important;
}

.ui.top.inverted.sidebar {
  background-color: #373d4b !important;
  height: 300px !important;
}

.dashboard .ui.divided.segment.items,
.sidebar .ui.divided.segment.items {
  background: rgba(255, 255, 255, 0.95) !important;
  overflow: auto;
  max-height: 230px;
}

.ui.sidebar.opened {
  background: none !important;
  box-shadow: none !important;
  border: none;
  display: flex;
  /* direction: rtl; */
}

.ui.visible.sidebar {
  overflow-y: visible !important;
}

.ui.sidebar.opened .segment {
  flex: 1;
  min-height: 100% !important;
}

.pusher.opened {
  padding-left: 350px;
  margin-left: -350px;
  margin-right: 0;
  width: 100%;
  min-height: 100% !important;
  display: flex;
  z-index: 101;
}

.pusherwrapper.pusher {
  padding-bottom: 4 * @relativeSmall;
  // padding-top: 6*@relativeSmall;
  height: 100%;
}

.pusherwrapper.pusher.dimmed {
  z-index: 101;
}

.pusher.opened .flex-row,
.modal .flex-row {
  flex: 1;
  display: flex;
  flex-direction: row;
}

.pusher.opened .flex-column,
.modal .flex-column {
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.pusher.opened>.flex-row>.basic.segment,
.pusher.opened>.flex-column>.basic.segment {
  padding-left: 0;
}

.pusher.opened .segment.mainframe,
.modal .segment.mainframe,
.pusher.opened .segment.mainframe>.segment,
.modal .segment.mainframe>.segment {
  flex: 1;
  display: flex;
  overflow: auto;
  /* min-height: 100% !important; */
}

.segment.flex-row.noheight {
  flex: none;
  flex-direction: column;
}

.vertical.stretched.modal {
  min-height: 80% !important;
}

.vertical.stretched.modal .segment {
  min-height: 100% !important;
  margin: 0 !important;
}

.pusher.opened .segment {
  margin: 0;
}

.pusher.opened>.flex-column .segment.mainframe {
  margin-top: -2rem;
}

.stub {
  position: relative;
  height: 240px;
}

.stretch.stub {
  position: relative;
  width: 100%;
  height: 160px;
}

.ui.dimmer:not(.inverted) {
  background-color: rgba(100, 130, 130, 0.5) !important;
}

.image>.ui.dimmer {
  cursor: pointer;
}

.stub>.ui.dimmer {
  background-color: rgba(0, 0, 0, 0.2) !important;
}

.stub>.ui.inverted.dimmer {
  background-color: rgba(255, 255, 255, 0.5) !important;
}

.stub>.ui.dimmer .inverted.header {
  color: #ccc !important;
}

.micro.item {
  font-size: 12px !important;
  line-height: 1em !important;
}

.micro.item>.content>.description,
.micro.item>.content>.meta,
.micro.item>.content>.extra {
  line-height: 1em !important;
  margin: 0.3em 0em 0.3em !important;
}

.events>.micro.item>.content>.description,
.events>.micro.item>.content>.meta,
.events>.micro.item>.content>.extra {
  line-height: 1em !important;
  margin: 0em !important;
}

.ui.list .dropdown:not(.selection) .menu,
.ui.list .dropdown:not(.selection) .menu .item {
  padding: 0.78571429rem 1.14285714rem !important;
  background-color: #fff !important;
}

.ui.list .dropdown:not(.selection) .menu .item .icon {
  display: inline-block !important;
}

.card .visible.content>.ui.image {
  overflow: hidden;
  height: -webkit-fill-available;
}

.infoline {
  position: relative;
  width: 100%;
  margin-top: -1px !important;
  margin-bottom: 1px;
}

.infoline .ui.message .small.icon {
  font-size: 1.2em !important;
}

/* NOTIFICATIONS */

.notifications {
  position: fixed;
  bottom: 0;
  right: 0;
  padding: 1rem;
  width: 300px;
}

.notifications>.ui.floating.message {
  box-shadow: 0px 0px 0px 1px rgba(34, 36, 38, 0.22) inset, 0px 2px 4px 0px rgba(34, 36, 38, 0.12),
    0px 2px 10px 0px rgba(34, 36, 38, 0.15) !important;
}

/* DROPDOWN */
.ui.dropdown .header .ui.label {
  font-size: 0.8rem !important;
  width: 2.4rem !important;
  border-radius: 499rem !important;
}

.ui.huge.dropdown {
  font-size: 1.42857143em !important;
}

.ui.search.dropdown.selection>.text {
  overflow: visible !important;
}

.ui.search .prompt {
  border-radius: 0 !important;
}

.fluid,
.ui.search.fluid,
.ui.search.fluid .prompt,
.ui.search.fluid .ui.input {
  width: 100%;
}

.result-wrapper {
  overflow-y: auto !important;
  max-height: 400px;
  margin-top: 0 !important;
}

.ui.search .header {
  display: block;
  overflow: hidden;
  font-size: 1em;
  padding: 0.78571429em 1.14285714em;
  color: rgba(0, 0, 0, 0.57);
  line-height: 1.33;
  background: #f9f9f9;
}

.ui.selection.dropdown .menu>.item {
  white-space: nowrap !important;
}

.ui.selection.dropdown .menu.virtual {
  border: none !important;
  margin: 0 !important;
  left: -2px;
  padding-left: 2px;
}

/* TIMELINE */
.for-timeline .vertical-timeline {
  width: 95%;
  max-width: 1170px;
  margin: 1em auto 2em;
  position: relative;
  padding: 1em 0 2em;
}

.for-timeline .vertical-timeline-element {
  margin: 2em 0;
}

.for-timeline .vertical-timeline-element-content {
  position: relative;
  margin-left: 60px;
  background: #fff;
  border-radius: 0;
  padding: 0;
  box-shadow: none;
}

.for-timeline .vertical-timeline-element-icon {
  position: absolute;
  top: 0;
  left: 0;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  box-shadow: 0 0 0 2px #fff, inset 0 2px 0 rgba(0, 0, 0, 0.08), 0 2px 2px 2px rgba(0, 0, 0, 0.1);
  background: #b0bec5;
  color: #fff;
}

.for-timeline .vertical-timeline-element--work .vertical-timeline-element-icon {
  background: #78909c;
  color: #fff;
}

.for-timeline .vertical-timeline-element--education .vertical-timeline-element-icon {
  background: #cfd8dc;
  color: #607d8b;
}

.for-timeline .vertical-timeline::before {
  background: #b0bec5;
}

.for-timeline .vertical-timeline-element-icon>i.icon {
  display: block;
  width: 24px;
  height: 24px;
  position: relative;
  left: 50%;
  top: 52%;
  margin-left: -12px;
  margin-top: -12px;
  font-size: 19px;
}

/* FIXES */
.react-datepicker .react-datepicker-container .react-datepicker-body .react-datepicker-row .react-datepicker-picker.selected {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

td.oneLineHidden {
  white-space: nowrap;
  height: 30px;
  overflow-x: hidden;
  background: #262626;
  width: auto;
  /* Для старых браузеров */
  background: linear-gradient(to left, transparent 0%, #262626 30%, #262626 100%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}

.ReactVirtualized__Grid__innerScrollContainer div>.collapsable {
  white-space: nowrap;
  height: 25px;
  overflow: hidden;
  width: 100%;
  line-height: 2.3;
}

.virt-cell {
  padding: 7px 5px;
  border-bottom: 1px solid #eee;
}

.virt-cell.header {
  font-weight: 600;
}

/* td > .collapsable:hover{
	display: block;
	position: relative;
	white-space: normal;
	height: 30px;
	overflow: visible;

}
td > .collapsable ul{
	height: 30px;
	overflow: hidden;
}
td > .collapsable:hover ul{
	display: block;
	position: absolute;
	left: 0;
	top: 0;
	z-index: 1000;
	height: auto;
	width: auto;
	background: #fff; */
/* Для старых браузеров */
/* -webkit-background-clip: none;
  background-clip: none;
  color: #262626;
	z-index: 1000;
	box-shadow: 0 0 10px rgba(0,0,0,0.5);
	height: auto;
	padding: 5px !important;
}
td > .collapsable ul li{
	white-space: nowrap;
	height: 30px;

  color: #262626;
} */

.collapsable.segment {
  width: 0;
  overflow: hidden;
  transition: width 0.5s !important;
  padding: 0 !important;
}

.collapsable.segment.open {
  width: 200px;
  /* min-width: max-content; */
  overflow-y: auto;
}

.mainframe .ui.bottom.sidebar {
  position: absolute;
  max-height: 70%;
}

.ui.menu.searchwrapper {
  //top: 3.6em !important;
}

.scrollwrapper {
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  padding-bottom: @relativeSmall*8;
  outline: none !important;
  user-select: text !important;
}

.scrollwrapper.bottomMenu {
  padding-bottom: @relativeSmall*6;
}

// start
.ui.divided.list .list .item,
.ui.divided.list .item,
.ui.divided.list {
  display: block !important;
  height: 100%;
  width: 100% !important;
}

.ui.divided.dozelist.list .list .item,
.ui.divided.dozelist.list .item,
.ui.divided.dozelist.list {
  display: block !important;
  height: auto !important;
  width: 100% !important;
}

.ui.list.draginfo .item .icon {
  padding: 3px 0 0 0 !important;
}

.ui.list .item .content:not(.feed) {
  overflow: hidden !important;
}

.ui.divided.selection.list .list .item,
.ui.divided.selection.list .item {
  margin: 0;
  border-radius: 0;
}

.ui.celled.selection.list .list .item,
.ui.celled.selection.list .item,
.ui.divided.selection.list .list .item,
.ui.divided.selection.list .item {
  border-radius: 0;
}

.ui.selection.list .list .item:last-child,
.ui.selection.list .item:last-child {
  margin-bottom: 0;
}

.ui.divided.list>div>div:not(:first-child) .item,
.ui.divided.list>div .item:not(:first-child) {
  border-top: 1px solid rgba(34, 36, 38, 0.15);
}

.ui.divided.list .menu>.item:first-child {
  border-top: none;
}

.ui.selection.list .list .item,
.ui.selection.list .item {
  cursor: pointer;
  background: transparent;
  padding: 0.5em;
  margin: 0;
  color: rgba(0, 0, 0, 0.4);
  border-radius: 0.5em;
  transition: color 0.1s ease, padding-left 0.1s ease, background-color 0.1s ease;
}

.ui.list .list .item:last-child,
.ui.list .item:last-child,
ol.ui.list li:last-child,
ul.ui.list li:last-child {
  padding-bottom: 0;
}

.ui.list .list .item,
.ui.list .item,
ol.ui.list li,
ul.ui.list li {
  display: list-item;
  table-layout: fixed;
  list-style-type: none;
  list-style-position: outside;
  padding: 0.21428571em 0;
  line-height: 1.14285714em;
}

.ui.list .item:after,
.ui.list .list .item,
ol.ui.list li:first-child:after,
ul.ui.list li:first-child:after {
  content: "";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}

.ui.list .list .item .icon+.content,
.ui.list .list .item .image+.content,
.ui.list .item .icon+.content,
.ui.list .item .image+.content {
  display: table-cell;
  width: 100%;
  padding: 0 0 0 0.5em;
  vertical-align: top;
}

.ui.list .item .content {
  position: relative;
  overflow: visible;
  display: table-cell;
}

.ui.list .list .item .content,
.ui.list .item .content {
  line-height: 1.14285714em;
}

.ui.selection.list .list .item,
.ui.selection.list .item {
  cursor: pointer;
  background: transparent;
  padding: 0.5em;
  margin: 0;
  color: rgba(0, 0, 0, 0.4);
  border-radius: 0.5em;
  transition: color 0.1s ease, padding-left 0.1s ease, background-color 0.1s ease;
}

.ui.list .list .item,
.ui.list .item,
ol.ui.list li,
ul.ui.list li {
  display: list-item;
  table-layout: fixed;
  list-style-type: none;
  list-style-position: outside;
  padding: 0.21428571em 0;
  line-height: 1.14285714em;
}

.ui.list .item .content {
  position: relative;
  overflow: visible;
  display: table-cell;
}

.ui.list .list .item [class*="left floated"],
.ui.list .item [class*="left floated"] {
  float: left;
  margin: 0 1em 0 0;
}

.ui.list .list .item .content,
.ui.list .item .content {
  line-height: 1.14285714em;
}

.ui.list .list .item a,
.ui.list .item a {
  cursor: pointer;
}

.ui.list .item .icon.warning.circle {
  text-shadow: 0.5px 0.5px 1px #bbb;
}

.ui.list .list .item i.icon,
.ui.list .item i.icon {
  display: table-cell;
  margin: 0;
  padding-top: 0.07142857em;
  padding-right: 0.28571429em;
  vertical-align: top;
  transition: color 0.1s ease;
}

.ui.list .disabled.item {
  opacity: 0.5;
  background-color: #f9f9f9;
}

// end
.ui.list.drugslist>div,
.ui.list.drugslist>div>div {
  position: relative;
}

.ui.list.drugslist .item .border,
.ui.list.draginfo .item .border {
  transition: background-color 0.5s ease-in-out, opacity 0.3s ease-in-out, filter 0.3s ease-in-out;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  height: 100%;
  width: 5px;
  background: transparent;
}

.ui.list.drugslist .item {
  padding-left: 50px;
  position: relative;
  background-color: #f3f3f2;
  // padding-right: ;
}

.ui.list.drugslist .item .arrowholder {
  position: absolute;
  overflow: hidden;
  left: 0;
  top: 0.5em;
  bottom: 0.5em;
  width: 50px;
}

.ui.list.drugslist .item.nowarnings {
  padding-left: 18px;
}

.ui.list.drugslist .item.selected {
  margin-left: 0px;
  background: rgba(0, 0, 0, 0.03);
  color: rgba(0, 0, 0, 0.8);
}

.ui.list.drugslist .item .summary {
  font-size: 1.1em;
  line-height: 1.2em;
  color: #677684;
}

.ui.list.drugslist .item.noproto .summary {
  color: #072c71;
}

.ui.list.drugslist .item .summary .icon {
  display: inline-block;
  margin-left: 5px;
}

.ui.list.drugslist .item.selected .summary {
  font-weight: bold;
}

.ui.list.drugslist .item.unused .ui.feed .event,
.ui.list.drugslist .item.unused .warning.icon,
.ui.list.drugslist .item.unused .right.content {
  opacity: 0.3;
  filter: grayscale(0.4);
}

.ui.list.drugslist .item .right.content {
  position: absolute;
  right: 26px;
  z-index: 101;
}

.ui.list .item .icon.arrow.circle.right {
  text-shadow: 0.5px 0.5px 1px #bbb;
  line-height: 23px;
  position: relative;
  display: block;
  margin: 0 0;
  height: 26px;
  width: 26px;
  padding: 0 !important;
}

.ui.list .item .icon.warning.circle {
  text-shadow: 0.5px 0.5px 1px #bbb;
}

.ui.grid .column .icon.outline.circle {
  text-shadow: 0.5px 0.5px 2px #bbb;
  font-size: 50px;
  padding: 0;
}

.ui.grid.changefg .column .icon.outline.circle {
  font-size: 40px;
  padding: 0;
}

.changefg .conflict .big.icons>img {
  width: 20px;
  margin-left: 10px;
  margin-top: 4px;
}

.changefg .conflict .big.icons {
  line-height: 27px;
  height: 40px;
  width: 40px !important;
  padding-top: 6px;
}

.changefg.grid {
  float: right;
  padding-right: 10px;
}

.changefg .conflict.column {
  width: 54px !important;
  padding-top: 0 !important;
  overflow: visible;
}

.changefg .conflict.auto.column {
  width: auto !important;
}

.fgdrugs {
  margin-top: 2em;
}

.ui.styled.accordion .accordion .fgdrugs .content {
  padding: 0 !important;
}

.fgdrugs .item {
  position: relative;
}

.fgdrugs .item .border {
  width: 5px;
  height: calc(100% - 5px);
  float: left;
  margin-right: 10px;
  display: block;
  position: absolute;
}

.fgdrugs .item .content {
  margin-left: 10px !important;
}

.fgdrugs .item .ui.feed .summary,
.fgdrugs .ui.feed .meta {
  white-space: normal !important;
}

.warnstack {
  display: flex;
  flex-direction: row-reverse;
  padding-right: 21px;
  padding-left: 0px;
}

.warnstack.open {
  padding-right: 0;
  padding-left: 0;
}

.ui.grid.changefg .conflict .big.icons.selected {
  border-radius: 100%;
  outline: 1px solid #fff;
  background: #fff;
}

.changefg .warnstack .conflict.column {
  margin-right: -49px;
}

.changefg .warnstack.open .conflict.column {
  margin-right: 0;
}

.changefg .warncontainer {}

.changefg .warncontainer.open {
  margin-right: -13px;
  padding-right: 0;
}

.ui.list .item .arrowBg {
  transition: margin-left 0.3s ease-in-out, background-color 0.3s ease-in-out;
  position: absolute;
  margin-left: -5px;
  text-align: right;
  padding-left: 16px;
  margin-top: 0.2em;
  width: 40px;
  height: 23px;
  border-radius: 0 20px 20px 0;
  background-color: #ccc;
  box-shadow: 0.5px 0.5px 1px #bbb;
}

.ui.list .item .arrowBg.none {
  margin-left: -55px;
}

.ui.list .forlist {
  display: table-cell;
  padding-right: 20px;
}

.conflict>.conflicticon.arrowBg {
  transition: margin-top 0.3s ease-in-out, background-color 0.3s ease-in-out;
  position: absolute;
  margin-top: -44px;
  text-align: right;
  padding-left: 0px;
  width: 26px;
  height: 26px;
  border-radius: 20px;
  background-color: #ccc;
  box-shadow: 0.5px 0.5px 2px #bbb;
  padding: 0;
  margin-left: 12px;
  //left: 24px;
}

.conflict>.conflicticon.arrowBg.none {
  // display: none;
  margin-top: -100px;
}

.conflict>.conflicticon.arrowBg>.icon {
  font-size: 28px;
  text-align: left;
  margin-top: -1px;
  margin-left: -1px;
  text-shadow: 0.5px 0.5px 1px #bbb;
}

.ui.feed {
  overflow: auto;
}

.ui.feed .summary,
.ui.feed .meta {
  white-space: nowrap !important;
}

.conflict.column {
  width: 74px !important;
  overflow: hidden;
  padding-top: 4.5em !important;
}

.conflict .big.icons {
  line-height: 27px;
  height: 50px;
  width: 50px !important;
  padding-top: 11px;
}

.conflict .big.icons.gray {
  transition: opacity 0.3s ease-in-out, filter 0.3s ease-in-out;
  opacity: 0.3;
  filter: grayscale(0.4);
}

.conflict .big.icons.selected {
  opacity: 1;
  filter: none;
}

.conflict .big.icons>img {
  width: 28px;
  margin-left: 11px;
  margin-top: 0px;
}

.agecontainer {
  position: absolute;
  top: 4px;
  left: 13px;
  font-size: 0.9em;
  width: 24px;
  text-align: center;
  font-style: normal;
}

.registerIconsContainer {
  margin-left: 15px;
  margin-top: -5px;
}

.registerIconsContainer img {
  position: relative !important;
  width: 36px !important;
  left: auto !important;
  transform: none !important;
  height: auto !important;
  margin: auto !important;
  opacity: 0.5;

}

.registerIconsContainer img:hover,
.registerIconsHasForm img{
  opacity: 1;

}

.dvConflictBlock {
  display: inline-flex !important;
  flex-direction: column;
}

.dvConflictBlock label {
  font-weight: 700;
}

.dvConflictDesc {
  // display: inline-flex !important;
  font-size: 12px;
}

.slick-slider,
.slick-list,
.slick-track,
.slick-slide,
.slick-slide>div {
  height: 100%;
}

.with-steps {
  padding-top: @relativeSmall*5.6;
}

.with-steps .no-padding {
  padding-top: 0;
}

.with-steps>.steps {
  position: absolute;
  top: 0;
  left: 0;
}

.with-steps>.steps>.step {
  height: @relativeSmall*8;
}

.with-steps>.steps>.step>.content {
  max-width: 150px;
}

.birthdate {
  display: flex;
  width: 100%;
}

.birthdate>.ui.input,
.birthdate>.ui.search {
  flex: 1;
}

.birthdate input {
  width: 100%;
}

.birthdate>.ui.button.basic {
  margin: 0;
  border-bottom: 1px solid #dededf;
  border-top: 1px solid #dededf;
  box-shadow: 0px 0px 0px 0px;
  cursor: default;
}

.birthdate>.ui.button.basic:hover {
  background: none !important;
}

//Schedule
.ui.segment.day>.leftPadded {
  // padding-left: 4*@relativeSmall;
  // margin-top: 0 !important;
}

.ui.segment.day .ui.divider {
  font-size: 0.7em;
  margin: 0.6em 0;
}

//tabs
.ui.inverted.menu {
  background: none !important;
}

.ui.inverted.menu>.item {
  opacity: 0.7;
}

.ui.inverted.menu>.item.active {
  opacity: 1;
}

.warningcontainer.vertical.segment {
  position: relative;
  padding-left: 40px !important;
}

.warningcontainer.segment .list {
  margin-top: 0;
}

.warningcontainer.segment .list .item {
  min-height: 38px;
  position: relative;
}

.warningline {
  position: absolute;
  width: 20px;
  height: 6px;
  left: -30px;
  top: 10px;
}

.arrowcontainer {
  position: absolute;
  left: 10px;
  top: 22px;
  bottom: 32px;
  border-width: 6px 0 6px 6px;
  border-radius: 5px 0 0 5px;
  width: 20px;
  border-style: solid;
}

.iconround {
  position: absolute;
  left: -16px;
  top: 50%;
  margin-top: -16px;
  width: 28px;
  height: 28px;
  padding: 3px;
  border-radius: 20px;
}

.arrowcontainer>.angle {
  position: absolute;
  left: 0;
}

.toparrow {
  top: -15px;
}

.bottomarrow {
  bottom: -15px;
}

.ui.image.cardbg {
  height: 70px;
  position: relative;
  overflow: hidden !important;
  background: @offWhite !important;
}

.ui.image.cardbg .icon {
  position: absolute;
  font-size: 40px;
  top: 15px;
  left: 30px;
  line-height: 40px;
  color: @blue;
}

.ui.image.cardbg .icon.rotated {
  font-size: 200px;
  top: -84px;
  right: 15px;
  left: auto;
  line-height: 200px;
  color: #f0f3f5;
  transform: ~"rotate(45deg)";
}

.concept_container .ui.label {
  margin-bottom: 5px;
}

.concept_container .ui.selection.dropdown {
  min-width: 1em;
}

.concept_container .ui.dropdown .menu {
  min-width: max-content;
}

.concept_container .ui.label label {
  margin-left: 5px;
  margin-right: 5px;
}

.ui.list .item:after,
.ui.list .list .item,
ol.ui.list li:first-child:after,
ul.ui.list li:first-child:after {
  visibility: visible !important;
}

.liquidcontainer {
  border-width: 3px;
  border-style: dashed;
  border-color: @lightGrey;
  border-left: 0px;
  margin: 0 3px 5px 0;
}

.liquidcontainer .bottomtext {
  padding: 5px 10px;
  font-size: @fontSize;
  border-top: 1px solid rgba(34, 36, 38, 0.15);
  color: @lightRed;
}

.liquidcontainer .text {
  padding: 10px;
  font-size: @fontSize;
}

.liquidcontainer .abstext {
  padding: 10px;
  font-size: @small;
}

.liquidcontainer.active .abstext {
  position: absolute !important;
}

.liquidcontainer .item .right.content {
  right: 20px !important;
}

.dragging {
  opacity: 0.8;
}

.item .draghandler {
  position: absolute;
  right: 4px;
  top: 9px;
  width: 17px;
  padding: 1px 2px 2px 1px;
  background: #f8f8f8;
  box-shadow: -1px -1px 0px #ddd;
}

.item .draghandler>div {
  width: 3px;
  height: 3px;
  margin: 2px;
  float: left;
  background: rgba(0, 0, 0, 0.6);
}

.glowing {
  color: rgba(0, 0, 0, 0.6);
}

@keyframes glowing {
  0% {
    color: rgba(0, 0, 0, 0.6);
  }

  50% {
    color: rgba(100, 0, 0, 1);
  }

  100% {
    color: rgba(0, 0, 0, 0.6);
  }
}

.glowing {
  animation: glowing 1000ms infinite;
}

.ReactCollapse--collapse {
  transition: height 300ms;
}

// clinrecs
.clinrec {}

.clinrec.ui.list .item {
  border-top: none !important;
  border-bottom: none !important;
}

.checkContainer {
  padding-left: 25px;
  position: relative;
}

.checkContainer .ui.checkbox {
  position: absolute;
  left: 2px;
  top: 5px;
}

.clinrec_group {
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
}

// loader
.gradient,
.ui.feed>.event>.content .meta.gradient {
  animation-duration: 1.8s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: #eaeae3;
  background: linear-gradient(to right, #e0e0e0 8%, #e9e8ea 38%, #e0e0e0 54%);
  background-size: 1000px 640px;
  min-width: 50px;
  min-height: 16px;
  width: 100%;
  position: relative;
  text-indent: -9999px;
}

.ui.styled.accordion .title,
.ui.styled.accordion .accordion .title {
  color: rgba(0, 0, 0, 0.87) !important;
}

.ui.styled.accordion .title.disabled,
.ui.styled.accordion .accordion .title.disabled {
  color: rgba(0, 0, 0, 0.4) !important;
}

@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }

  100% {
    background-position: 468px 0;
  }
}

.recipeCount {
  border: 1px solid #ccc;
  margin: 3px 5px;
  width: 35px;
}