.alertBlinker {
  animation: blinker 1s linear infinite;
  background: yellow;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.search-result-subtitle {
  font-size: 12px;
  margin-left: 23px;
  color: gray;
}